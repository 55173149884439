var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.$vuetify.breakpoint.smAndDown
    ? _c(
        "v-toolbar",
        {
          staticClass: "my-0 py-0 app-bar",
          class: { compact: _vm.isCompact, "app-bar-cycle": _vm.showCycle },
          attrs: { color: "transparent", elevation: "1", id: "toolbar" },
        },
        [
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-row",
                { staticClass: "app-bar-sm-down", attrs: { align: "center" } },
                [
                  !_vm.isGenericView
                    ? _c(
                        "pb-btn",
                        {
                          staticClass: "mr-4",
                          attrs: {
                            label: _vm.$t("goToLabel"),
                            icon: "",
                            id: "nav",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setDrawerLeft(!_vm.drawerLeft)
                            },
                          },
                        },
                        [
                          _vm.withBadge
                            ? _c(
                                "v-badge",
                                {
                                  attrs: {
                                    dot: "",
                                    color: "error",
                                    "offset-x": "4",
                                    "offset-y": "2",
                                  },
                                },
                                [_c("v-icon", [_vm._v("fal fa-bars")])],
                                1
                              )
                            : _c("v-icon", [_vm._v("fal fa-bars")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        id: "appbarCenterItems",
                        cols: "auto",
                        align: "center",
                      },
                    },
                    [
                      _vm.localPage !== "plans"
                        ? _c("div", { staticClass: "page-title" }, [
                            _vm._v(" " + _vm._s(_vm.localTitle) + " "),
                          ])
                        : _vm._e(),
                      !_vm.localPlansToolbarHidden
                        ? _c(
                            "div",
                            { attrs: { id: "plansNav" } },
                            [
                              _vm.viewType !== "L"
                                ? _c(
                                    "v-btn-toggle",
                                    {
                                      staticClass: "my-3",
                                      attrs: {
                                        id: "plansDatePicker",
                                        disabled: _vm.localAppBarDisabled,
                                        rounded: "",
                                        dense: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "nav-prev",
                                          attrs: {
                                            color: "primary",
                                            "active-class": "no-active",
                                            disabled:
                                              _vm.viewType === "C" &&
                                              _vm.cyclePage === 1,
                                            "aria-label":
                                              _vm.$t("previousLabel"),
                                          },
                                          on: { click: _vm.prevLessonsEvents },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "white" } },
                                            [_vm._v("fal fa-angle-left")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.userMode !== "S"
                                        ? _c(
                                            "v-menu",
                                            {
                                              attrs: { "offset-y": "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "switch-year",
                                                                class: {
                                                                  red: !_vm.isCurrentDateInSchoolYearRange,
                                                                  "lighten-4":
                                                                    !_vm.isCurrentDateInSchoolYearRange &&
                                                                    !_vm
                                                                      .$vuetify
                                                                      .theme
                                                                      .dark,
                                                                },
                                                                attrs: {
                                                                  elevation:
                                                                    "0",
                                                                  id: "yearList",
                                                                  "active-class":
                                                                    "no-active",
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fal fa-school",
                                                              style: {
                                                                "font-size":
                                                                  "20px",
                                                                color:
                                                                  _vm.$vuetify
                                                                    .theme
                                                                    .currentTheme
                                                                    .primary,
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2232842482
                                              ),
                                            },
                                            [
                                              _c(
                                                "perfect-scrollbar",
                                                {
                                                  staticStyle: {
                                                    "max-height":
                                                      "calc(100vh - 120px)",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list",
                                                    _vm._l(
                                                      _vm.localSchoolYearItems,
                                                      function (item, index) {
                                                        return _c(
                                                          "v-list-item",
                                                          {
                                                            key: index,
                                                            class: {
                                                              "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                                                +item.value ===
                                                                _vm.currentYearId,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.schoolYearChanged(
                                                                  {
                                                                    yearId:
                                                                      +item.value,
                                                                    teacherId:
                                                                      +_vm.currentTeacherId,
                                                                  }
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm.hasText(
                                                                  item.type
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        class: [
                                                                          "year-item-type",
                                                                          `year-item-type-${item.type.toLowerCase()}`,
                                                                        ],
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.type
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.text
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "goto-today px-2",
                                          attrs: {
                                            disabled:
                                              !_vm.isCurrentDateInSchoolYearRange,
                                          },
                                          on: { click: _vm.gotoToday },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("todayLabel"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm.viewType == "C"
                                        ? _c(
                                            "v-menu",
                                            {
                                              attrs: { "max-height": "400px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "goto-cycle",
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fal fa-list-alt",
                                                              style: {
                                                                "font-size":
                                                                  "20px",
                                                                color:
                                                                  _vm.$vuetify
                                                                    .theme
                                                                    .currentTheme
                                                                    .primary,
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2557094877
                                              ),
                                            },
                                            [
                                              _c(
                                                "v-list",
                                                _vm._l(
                                                  _vm.cyclePages,
                                                  function (page, i) {
                                                    return _c(
                                                      "v-list-item",
                                                      {
                                                        key: i,
                                                        class: {
                                                          "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                                            +i ===
                                                            _vm.cyclePage,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeCyclePage(
                                                              +i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(i) +
                                                                ": " +
                                                                _vm._s(
                                                                  page.cycleStart
                                                                ) +
                                                                " - " +
                                                                _vm._s(
                                                                  page.cycleEnd
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-menu",
                                            {
                                              ref: "gotoDateMenu",
                                              attrs: {
                                                transition: "scale-transition",
                                                "min-width": "auto",
                                                "close-on-content-click": false,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "goto-date",
                                                                attrs: {
                                                                  "aria-label":
                                                                    _vm.$t(
                                                                      "goToDate"
                                                                    ),
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.gotoDateMenu = true
                                                                    },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fal fa-calendar-day",
                                                              style: {
                                                                "font-size":
                                                                  "20px",
                                                                color:
                                                                  _vm.$vuetify
                                                                    .theme
                                                                    .currentTheme
                                                                    .primary,
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2364220599
                                              ),
                                              model: {
                                                value: _vm.gotoDateMenu,
                                                callback: function ($$v) {
                                                  _vm.gotoDateMenu = $$v
                                                },
                                                expression: "gotoDateMenu",
                                              },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                ref: "datePicker",
                                                attrs: {
                                                  value: _vm.pickerGotoDate,
                                                  locale: _vm.getGlobalLanguage,
                                                },
                                                on: {
                                                  change:
                                                    _vm.onPickerFirstDayChanged,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "nav-next",
                                          attrs: {
                                            color: "primary",
                                            "active-class": "no-active",
                                            disabled:
                                              _vm.viewType === "C" &&
                                              _vm.cyclePage ===
                                                Object.keys(_vm.cyclePages)
                                                  .length,
                                            "aria-label": _vm.$t("nextLabel"),
                                          },
                                          on: { click: _vm.nextLessonsEvents },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "white" } },
                                            [_vm._v("fal fa-angle-right")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : !_vm.$currentUser.isStudent
                                ? _c("school-year-selector")
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "pb-btn",
                    {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.hideRightDrawer,
                          expression: "hideRightDrawer",
                        },
                      ],
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        label: _vm.$t("settingsLabel"),
                        color: "rgba(0, 0, 0, 0.64)",
                        disabled: _vm.localAppBarDisabled,
                        text: "",
                        id: "account",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.isGenericView
                            ? undefined
                            : (_vm.localDrawerRight = !_vm.localDrawerRight)
                        },
                      },
                    },
                    [
                      _vm.hasProfilePhoto
                        ? _c("v-avatar", { attrs: { size: "36" } }, [
                            _c("img", {
                              staticStyle: { "object-fit": "cover" },
                              attrs: {
                                src: _vm.profilePhotoUrl,
                                alt: _vm.displayName,
                              },
                            }),
                          ])
                        : _c("v-icon", [_vm._v("fas fa-user-circle")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-row",
                { staticClass: "app-bar-not-sm", attrs: { align: "center" } },
                [
                  _vm.isLeftDrawerMenuTypeHidden && !_vm.isGenericView
                    ? _c(
                        "pb-btn",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            label: _vm.$t("goToLabel"),
                            icon: "",
                            id: "nav",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setDrawerLeft(!_vm.drawerLeft)
                            },
                          },
                        },
                        [
                          _vm.withBadge
                            ? _c(
                                "v-badge",
                                {
                                  attrs: {
                                    dot: "",
                                    color: "error",
                                    "offset-x": "4",
                                    "offset-y": "2",
                                  },
                                },
                                [_c("v-icon", [_vm._v("fal fa-bars")])],
                                1
                              )
                            : _c("v-icon", [_vm._v("fal fa-bars")]),
                        ],
                        1
                      )
                    : !_vm.isGenericView
                    ? _c(
                        "pb-btn",
                        {
                          staticClass: "mr-12",
                          attrs: {
                            label: _vm.$t("goToLabel"),
                            icon: "",
                            id: "nav",
                          },
                        },
                        [_c("v-icon", [_vm._v("fal fa-bars")])],
                        1
                      )
                    : _vm._e(),
                  _vm.localPage !== "plans"
                    ? _c(
                        "pb-btn",
                        {
                          staticClass: "mr-4 my-1",
                          attrs: {
                            label: _vm.$t("plansLabel"),
                            color: "primary",
                            disabled: _vm.localAppBarDisabled,
                            text: "",
                            outlined: "",
                            to: "/plans",
                          },
                        },
                        [_c("v-icon", [_vm._v("fal fa-angles-left")])],
                        1
                      )
                    : _vm._e(),
                  _vm.$currentUser.isDualOrAllowViewOnly && _vm.canSwitchModes
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            disabled: _vm.localAppBarDisabled,
                            "offset-y": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-avatar",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: "grey darken-1 mr-1",
                                              size: "36",
                                            },
                                          },
                                          "v-avatar",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "white--text" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.userModeFirstLetter)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            715227887
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(
                              _vm.localUserModeList,
                              function (item, index) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: index,
                                    class: {
                                      "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                        _vm.userMode == item.value,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.userModeChanged(item.value)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(item.text)),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { attrs: { id: "filterPlans" } },
                    [
                      _vm.isCompact && !_vm.localPlansToolbarHidden
                        ? _c(
                            "div",
                            { staticClass: "select-view-types d-inline-block" },
                            [
                              _c("v-select", {
                                attrs: {
                                  solo: "",
                                  flat: "",
                                  "hide-details": "",
                                  dense: "",
                                  value: _vm.viewType,
                                  items: _vm.viewTypes,
                                },
                                on: { input: _vm.doChangeViewType },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "d-inline-block" },
                            [
                              !_vm.localPlansToolbarHidden &&
                              !_vm.$vuetify.breakpoint.mobile
                                ? _c(
                                    "v-btn-toggle",
                                    {
                                      staticClass: "d-block ml-1",
                                      attrs: {
                                        color: "primary",
                                        disabled: _vm.localAppBarDisabled,
                                        value: _vm.viewType,
                                        mandatory: "",
                                        dense: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { value: "D" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.doChangeViewType("D")
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("dayLabel"))),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { value: "W" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.doChangeViewType("W")
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("weekLabel"))),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { value: "M" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.doChangeViewType("M")
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("monthLabel"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm.showCycle &&
                                      !_vm.$currentUser.isStudent
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                value: "C",
                                                disabled: !_vm.isBetween(
                                                  _vm.currentDay,
                                                  _vm
                                                    .getCycleScheduleDateStartEnd
                                                    .start,
                                                  _vm
                                                    .getCycleScheduleDateStartEnd
                                                    .end
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.doChangeViewType(
                                                    "C"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("cycleLabel"))
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { value: "L" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.doChangeViewType("L")
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("listLabel"))),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                      !_vm.localPlansToolbarHidden
                        ? _c(
                            "pb-btn",
                            {
                              staticClass: "d-inline-block ml-3 my-1",
                              attrs: {
                                width: "40",
                                color: "primary",
                                label: _vm.$t("filterPlansLabel"),
                                text: "",
                                outlined: "",
                                disabled: _vm.localAppBarDisabled,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.showDrawerBottom.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("fal fa-sliders-h")])],
                            1
                          )
                        : _vm._e(),
                      !_vm.localPlansToolbarHidden
                        ? _c(
                            "pb-btn",
                            {
                              staticClass: "d-inline-block ml-2 my-1",
                              attrs: {
                                label: _vm.showDetails
                                  ? _vm.$t("hideDetailsLabel")
                                  : _vm.$t("showDetailsLabel"),
                                color: "primary",
                                disabled: _vm.localAppBarDisabled,
                                text: "",
                                outlined: "",
                                id: "hideShowDetails",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showDetails = !_vm.showDetails
                                },
                              },
                            },
                            [
                              _vm.showDetails
                                ? _c("v-icon", [
                                    _vm._v("fal fa-arrow-up-to-line"),
                                  ])
                                : !_vm.showDetails
                                ? _c("v-icon", [
                                    _vm._v("fal fa-arrow-down-from-line"),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _vm.userMode == "S" && _vm.studentId > 0
                        ? _c("v-select", {
                            staticClass: "select-fit text-caption ml-1",
                            attrs: {
                              items: _vm.studentTeacherItems,
                              "no-data-text": "None",
                              max: "0",
                              disabled: _vm.localAppBarDisabled,
                              "hide-details": "",
                              solo: "",
                              flat: "",
                            },
                            model: {
                              value: _vm.studentTeacherId,
                              callback: function ($$v) {
                                _vm.studentTeacherId = $$v
                              },
                              expression: "studentTeacherId",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      style: _vm.getCenterOffset,
                      attrs: {
                        id: "appbarCenterItems",
                        cols: "auto",
                        align: "center",
                      },
                    },
                    [
                      _vm.localPage !== "plans"
                        ? _c("div", { staticClass: "page-title" }, [
                            _vm._v(" " + _vm._s(_vm.localTitle) + " "),
                          ])
                        : _vm._e(),
                      !_vm.localPlansToolbarHidden
                        ? _c(
                            "div",
                            { attrs: { id: "plansNav" } },
                            [
                              _vm.viewType !== "L"
                                ? _c(
                                    "v-btn-toggle",
                                    {
                                      staticClass: "my-3",
                                      attrs: {
                                        id: "plansDatePicker",
                                        disabled: _vm.localAppBarDisabled,
                                        rounded: "",
                                        dense: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "nav-prev",
                                          attrs: {
                                            color: "primary",
                                            "active-class": "no-active",
                                            disabled:
                                              _vm.viewType === "C" &&
                                              _vm.cyclePage === 1,
                                            "aria-label":
                                              _vm.$t("previousLabel"),
                                          },
                                          on: { click: _vm.prevLessonsEvents },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "white" } },
                                            [_vm._v("fal fa-angle-left")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.userMode !== "S"
                                        ? _c(
                                            "v-menu",
                                            {
                                              attrs: { "offset-y": "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "px-1 switch-year",
                                                                class: {
                                                                  red: !_vm.isCurrentDateInSchoolYearRange,
                                                                  "lighten-4":
                                                                    !_vm.isCurrentDateInSchoolYearRange &&
                                                                    !_vm
                                                                      .$vuetify
                                                                      .theme
                                                                      .dark,
                                                                },
                                                                attrs: {
                                                                  elevation:
                                                                    "0",
                                                                  id: "yearList",
                                                                  "active-class":
                                                                    "no-active",
                                                                  "aria-label":
                                                                    _vm.$t(
                                                                      "schoolYearSelectorLabel"
                                                                    ),
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fal fa-school",
                                                              style: {
                                                                "font-size":
                                                                  "20px",
                                                                color:
                                                                  _vm.$vuetify
                                                                    .theme
                                                                    .currentTheme
                                                                    .primary,
                                                              },
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "switch-year__text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.currentYearText
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                4088919547
                                              ),
                                            },
                                            [
                                              _c(
                                                "perfect-scrollbar",
                                                {
                                                  staticStyle: {
                                                    "max-height":
                                                      "calc(100vh - 120px)",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list",
                                                    _vm._l(
                                                      _vm.localSchoolYearItems,
                                                      function (item, index) {
                                                        return _c(
                                                          "v-list-item",
                                                          {
                                                            key: index,
                                                            class: {
                                                              "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                                                +item.value ===
                                                                _vm.currentYearId,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.schoolYearChanged(
                                                                  {
                                                                    yearId:
                                                                      +item.value,
                                                                    teacherId:
                                                                      +_vm.currentTeacherId,
                                                                  }
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm.hasText(
                                                                  item.type
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        class: [
                                                                          "year-item-type",
                                                                          `year-item-type-${item.type.toLowerCase()}`,
                                                                        ],
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.type
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.text
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "goto-today px-2",
                                          attrs: {
                                            disabled:
                                              !_vm.isCurrentDateInSchoolYearRange,
                                            "active-class": "no-active",
                                          },
                                          on: { click: _vm.gotoToday },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("todayLabel"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm.viewType == "C"
                                        ? _c(
                                            "v-menu",
                                            {
                                              attrs: { "max-height": "400px" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "px-1 goto-cycle",
                                                                attrs: {
                                                                  "active-class":
                                                                    "no-active",
                                                                  "aria-label":
                                                                    _vm.$t(
                                                                      "schoolYearSelectorLabel"
                                                                    ),
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fal fa-list-alt",
                                                              style: {
                                                                "font-size":
                                                                  "20px",
                                                                color:
                                                                  _vm.$vuetify
                                                                    .theme
                                                                    .currentTheme
                                                                    .primary,
                                                              },
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "goto-date__text",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.cyclePage
                                                                    ) +
                                                                      ": " +
                                                                      _vm._s(
                                                                        _vm.viewStart
                                                                      )
                                                                  ),
                                                                ]),
                                                                _c("span", [
                                                                  _vm._v("-"),
                                                                ]),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.viewEnd
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1059591984
                                              ),
                                            },
                                            [
                                              _c(
                                                "v-list",
                                                _vm._l(
                                                  _vm.cyclePages,
                                                  function (page, i) {
                                                    return _c(
                                                      "v-list-item",
                                                      {
                                                        key: i,
                                                        class: {
                                                          "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                                            +i ===
                                                            _vm.cyclePage,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeCyclePage(
                                                              +i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              _vm._s(i) +
                                                                ": " +
                                                                _vm._s(
                                                                  page.cycleStart
                                                                ) +
                                                                " - " +
                                                                _vm._s(
                                                                  page.cycleEnd
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-menu",
                                            {
                                              ref: "gotoDateMenu",
                                              attrs: {
                                                transition: "scale-transition",
                                                "min-width": "auto",
                                                "close-on-content-click": false,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "px-1 goto-date",
                                                                attrs: {
                                                                  "active-class":
                                                                    "no-active",
                                                                  "aria-label":
                                                                    _vm.$t(
                                                                      "goToDate"
                                                                    ),
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.gotoDateMenu = true
                                                                    },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fal fa-calendar-day",
                                                              style: {
                                                                "font-size":
                                                                  "20px",
                                                                color:
                                                                  _vm.$vuetify
                                                                    .theme
                                                                    .currentTheme
                                                                    .primary,
                                                              },
                                                            }),
                                                            [
                                                              _vm.viewType ===
                                                              "M"
                                                                ? [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "goto-date__text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.viewMonth
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                : [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "goto-date__text",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.viewStart
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "-"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.viewEnd
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                            ],
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3654196870
                                              ),
                                              model: {
                                                value: _vm.gotoDateMenu,
                                                callback: function ($$v) {
                                                  _vm.gotoDateMenu = $$v
                                                },
                                                expression: "gotoDateMenu",
                                              },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                ref: "datePicker",
                                                attrs: {
                                                  value: _vm.pickerGotoDate,
                                                  locale: _vm.getGlobalLanguage,
                                                },
                                                on: {
                                                  change:
                                                    _vm.onPickerFirstDayChanged,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "nav-next",
                                          attrs: {
                                            color: "primary",
                                            "active-class": "no-active",
                                            disabled:
                                              _vm.viewType === "C" &&
                                              _vm.cyclePage ===
                                                Object.keys(_vm.cyclePages)
                                                  .length,
                                            "aria-label": _vm.$t("nextLabel"),
                                          },
                                          on: { click: _vm.nextLessonsEvents },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "white" } },
                                            [_vm._v("fal fa-angle-right")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : !_vm.$currentUser.isStudent
                                ? _c("school-year-selector")
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c("v-spacer"),
                  !_vm.localPrintButtonHidden
                    ? _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "pb-btn",
                            {
                              staticClass: "ml-2 my-1",
                              attrs: {
                                id: "print",
                                color: "primary",
                                label: _vm.$t("sendToLabel"),
                                text: "",
                                outlined: "",
                                disabled: _vm.localAppBarDisabled,
                              },
                              on: { click: _vm.emitPrint },
                            },
                            [
                              _c("v-icon", [
                                _vm._v("fal fa-arrow-up-from-square"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.localPlansToolbarHidden
                    ? _c(
                        "v-col",
                        {
                          staticClass: "ma-0 pa-0 d-flex flex-nowrap",
                          attrs: { cols: "auto", id: "actionButtons" },
                        },
                        [
                          _vm.showTeacherStatusOptions
                            ? _c(
                                "v-menu",
                                {
                                  attrs: { "close-on-content-click": false },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on: onMenu }) {
                                          return [
                                            _c(
                                              "pb-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "ml-2 my-1 d-inline-block",
                                                  style: {
                                                    "background-color":
                                                      _vm.haActiveStatus
                                                        ? _vm.activeStatusColor
                                                        : "",
                                                    "border-radius": "5px",
                                                  },
                                                  attrs: {
                                                    label:
                                                      _vm.$t("applyWeekTags"),
                                                    color: "primary",
                                                    disabled:
                                                      _vm.localAppBarDisabled,
                                                    text: "",
                                                    outlined: "",
                                                  },
                                                },
                                                { ...onMenu }
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    style: {
                                                      color: _vm.haActiveStatus
                                                        ? "white"
                                                        : "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.activeStatusLabel
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4097215976
                                  ),
                                  model: {
                                    value: _vm.showStatusMenu,
                                    callback: function ($$v) {
                                      _vm.showStatusMenu = $$v
                                    },
                                    expression: "showStatusMenu",
                                  },
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "ml-2 mr-2",
                                              attrs: {
                                                align: "center",
                                                justify: "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-weight-bold mb-1 mx-1",
                                                  style: {
                                                    color:
                                                      _vm.$vuetify.theme
                                                        .currentTheme.info,
                                                    "font-size": "12px",
                                                    "max-width": "280px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.displayViewStart
                                                      ) +
                                                      " - " +
                                                      _vm._s(
                                                        _vm.displayViewEnd
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._l(
                                        _vm.localAllSchoolStatus,
                                        function (status) {
                                          return _c(
                                            "v-list-item",
                                            {
                                              key: "s" + status.schoolStatusId,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addRemoveSchoolStatus(
                                                    status
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "m-2",
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "justify-start",
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: { text: "" },
                                                        },
                                                        [
                                                          _c("v-icon", {
                                                            attrs: {
                                                              color:
                                                                status.color,
                                                            },
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  status.icon
                                                                ),
                                                            },
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  status.statusText
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          status.adminOnly
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "ml-2",
                                                                  attrs: {
                                                                    color:
                                                                      "grey",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fal fa-lock"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showAdminStatusOptions
                            ? _c(
                                "pb-btn",
                                {
                                  staticClass: "ml-2 my-1 d-inline-block",
                                  attrs: {
                                    label: _vm.$t("applyWeekTags"),
                                    color: "primary",
                                    disabled: _vm.localAppBarDisabled,
                                    text: "",
                                    outlined: "",
                                  },
                                  on: { click: _vm.openSchoolStatus },
                                },
                                [_c("v-icon", [_vm._v("fal fa-tags")])],
                                1
                              )
                            : _vm._e(),
                          _vm.$currentUser.isAdmin
                            ? _c(
                                "pb-btn",
                                {
                                  staticClass: "ml-2 my-1",
                                  attrs: {
                                    id: "print",
                                    color: "primary",
                                    label: _vm.$t("messageTeacherLabel"),
                                    text: "",
                                    outlined: "",
                                    disabled: _vm.localAppBarDisabled,
                                  },
                                  on: { click: _vm.messageTeacher },
                                },
                                [_c("v-icon", [_vm._v("fal fa-message")])],
                                1
                              )
                            : _vm._e(),
                          _vm.userMode === "T" ||
                          (_vm.$currentUser.isStudent &&
                            _vm.$currentUser.id > 0)
                            ? _c(
                                "pb-btn",
                                {
                                  directives: [
                                    {
                                      name: "click-outside",
                                      rawName: "v-click-outside",
                                      value: _vm.hideRightDrawerAdd,
                                      expression: "hideRightDrawerAdd",
                                    },
                                  ],
                                  staticClass: "ml-2 my-1",
                                  attrs: {
                                    id: "add",
                                    color: "primary",
                                    label:
                                      _vm.$currentUser.isStudent &&
                                      _vm.$currentUser.id > 0
                                        ? _vm.$t("addClassLabel")
                                        : _vm.$t("addLabel"),
                                    elevation: "0",
                                    disabled: _vm.localAppBarDisabled,
                                    text: "",
                                    outlined: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onAddMenuClick.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("fal fa-plus")])],
                                1
                              )
                            : _vm._e(),
                          _vm.$currentUser.isStudent && _vm.$currentUser.id > 0
                            ? _c(
                                "pb-btn",
                                {
                                  directives: [
                                    {
                                      name: "click-outside",
                                      rawName: "v-click-outside",
                                      value: _vm.hideRightDrawerAdd,
                                      expression: "hideRightDrawerAdd",
                                    },
                                  ],
                                  staticClass: "ml-2 my-1",
                                  attrs: {
                                    id: "edit",
                                    color: "primary",
                                    label: _vm.$t("editClassLabel"),
                                    elevation: "0",
                                    disabled: _vm.localAppBarDisabled,
                                    text: "",
                                    outlined: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onEditMenuClick.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("fal fa-list-check")])],
                                1
                              )
                            : _vm._e(),
                          _vm.isCompact && _vm.userMode === "T"
                            ? _c(
                                "v-menu",
                                {
                                  attrs: { bottom: "", "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on: onMenu }) {
                                          return [
                                            _c(
                                              "pb-btn",
                                              _vm._g(
                                                {
                                                  ref: "moreActionsButton",
                                                  staticClass: "ml-2 my-1",
                                                  attrs: {
                                                    id: "more",
                                                    color: "primary",
                                                    label:
                                                      _vm.$t(
                                                        "moreActionsLabel"
                                                      ),
                                                    elevation: "0",
                                                    disabled:
                                                      _vm.localAppBarDisabled,
                                                    text: "",
                                                    outlined: "",
                                                  },
                                                  on: {
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "down",
                                                          40,
                                                          $event.key,
                                                          ["Down", "ArrowDown"]
                                                        )
                                                      )
                                                        return null
                                                      return _vm.focusButton(
                                                        "copyButton"
                                                      )
                                                    },
                                                  },
                                                },
                                                { ...onMenu }
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fal fa-grid"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2843230716
                                  ),
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex white px-0 py-4",
                                      staticStyle: {
                                        "flex-wrap": "wrap",
                                        width: "180px",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _vm.userMode === "T"
                                        ? _c(
                                            "pb-btn",
                                            {
                                              ref: "copyButton",
                                              staticClass:
                                                "ml-2 my-1 d-inline-block",
                                              attrs: {
                                                id: "copy",
                                                label: _vm.$t("copyLabel"),
                                                color: "primary",
                                                disabled:
                                                  _vm.localAppBarDisabled,
                                                text: "",
                                                outlined: "",
                                              },
                                              on: {
                                                click: _vm.openCopyPage,
                                                keyup: [
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "up",
                                                        38,
                                                        $event.key,
                                                        ["Up", "ArrowUp"]
                                                      )
                                                    )
                                                      return null
                                                    return _vm.focusButton(
                                                      "moreActionsButton"
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "down",
                                                        40,
                                                        $event.key,
                                                        ["Down", "ArrowDown"]
                                                      )
                                                    )
                                                      return null
                                                    return _vm.focusButton(
                                                      "sendToButton"
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "right",
                                                        39,
                                                        $event.key,
                                                        ["Right", "ArrowRight"]
                                                      )
                                                    )
                                                      return null
                                                    if (
                                                      "button" in $event &&
                                                      $event.button !== 2
                                                    )
                                                      return null
                                                    return _vm.focusButton(
                                                      "searchButton"
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "escape",
                                                        undefined,
                                                        $event.key,
                                                        undefined
                                                      )
                                                    )
                                                      return null
                                                    return _vm.focusButton(
                                                      "moreActionsButton"
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fal fa-copy"),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.userMode === "T"
                                        ? _c(
                                            "pb-btn",
                                            {
                                              ref: "searchButton",
                                              staticClass:
                                                "ml-2 my-1 d-inline-block",
                                              attrs: {
                                                id: "search",
                                                to: "/search",
                                                color: "primary",
                                                label:
                                                  _vm.$t("searchLessonsLabel"),
                                                disabled:
                                                  _vm.localAppBarDisabled,
                                                text: "",
                                                outlined: "",
                                              },
                                              on: {
                                                keyup: [
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "up",
                                                        38,
                                                        $event.key,
                                                        ["Up", "ArrowUp"]
                                                      )
                                                    )
                                                      return null
                                                    return _vm.focusButton(
                                                      "moreActionsButton"
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "down",
                                                        40,
                                                        $event.key,
                                                        ["Down", "ArrowDown"]
                                                      )
                                                    )
                                                      return null
                                                    return _vm.focusButton(
                                                      "sendToButton"
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "left",
                                                        37,
                                                        $event.key,
                                                        ["Left", "ArrowLeft"]
                                                      )
                                                    )
                                                      return null
                                                    if (
                                                      "button" in $event &&
                                                      $event.button !== 0
                                                    )
                                                      return null
                                                    return _vm.focusButton(
                                                      "copyButton"
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "right",
                                                        39,
                                                        $event.key,
                                                        ["Right", "ArrowRight"]
                                                      )
                                                    )
                                                      return null
                                                    if (
                                                      "button" in $event &&
                                                      $event.button !== 2
                                                    )
                                                      return null
                                                    return _vm.focusButton(
                                                      "sendToButton"
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "escape",
                                                        undefined,
                                                        $event.key,
                                                        undefined
                                                      )
                                                    )
                                                      return null
                                                    return _vm.focusButton(
                                                      "moreActionsButton"
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fal fa-search"),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.$currentUser.isStudent &&
                                      _vm.hasText(_vm.viewNotesText)
                                        ? _c(
                                            "pb-btn",
                                            {
                                              staticClass:
                                                "ml-2 my-1 d-inline-block",
                                              attrs: {
                                                color: "primary",
                                                label: _vm.$t(
                                                  "viewInformationLabel"
                                                ),
                                                text: "",
                                                outlined: "",
                                                disabled:
                                                  _vm.localAppBarDisabled,
                                              },
                                              on: {
                                                click: _vm.showViewNotesText,
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fal fa-info"),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "pb-btn",
                                        {
                                          ref: "sendToButton",
                                          staticClass:
                                            "ml-2 my-1 d-inline-block",
                                          attrs: {
                                            id: "print",
                                            color: "primary",
                                            label: _vm.$t("sendToLabel"),
                                            text: "",
                                            outlined: "",
                                            disabled: _vm.localAppBarDisabled,
                                          },
                                          on: {
                                            click: _vm.plansShowPrintOptions,
                                            keyup: [
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "up",
                                                    38,
                                                    $event.key,
                                                    ["Up", "ArrowUp"]
                                                  )
                                                )
                                                  return null
                                                return _vm.focusButton(
                                                  "copyButton"
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "left",
                                                    37,
                                                    $event.key,
                                                    ["Left", "ArrowLeft"]
                                                  )
                                                )
                                                  return null
                                                if (
                                                  "button" in $event &&
                                                  $event.button !== 0
                                                )
                                                  return null
                                                return _vm.focusButton(
                                                  "searchButton"
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "escape",
                                                    undefined,
                                                    $event.key,
                                                    undefined
                                                  )
                                                )
                                                  return null
                                                return _vm.focusButton(
                                                  "moreActionsButton"
                                                )
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              "fal fa-arrow-up-from-square"
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                { staticClass: "d-inline-block" },
                                [
                                  _vm.userMode === "T"
                                    ? _c(
                                        "pb-btn",
                                        {
                                          staticClass:
                                            "ml-2 my-1 d-inline-block",
                                          attrs: {
                                            id: "copy",
                                            label: _vm.$t("copyLabel"),
                                            color: "primary",
                                            disabled: _vm.localAppBarDisabled,
                                            text: "",
                                            outlined: "",
                                          },
                                          on: { click: _vm.openCopyPage },
                                        },
                                        [_c("v-icon", [_vm._v("fal fa-copy")])],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.userMode === "T"
                                    ? _c(
                                        "pb-btn",
                                        {
                                          staticClass:
                                            "ml-2 my-1 d-inline-block",
                                          attrs: {
                                            id: "search",
                                            to: "/search",
                                            color: "primary",
                                            label: _vm.$t("searchLessonsLabel"),
                                            disabled: _vm.localAppBarDisabled,
                                            text: "",
                                            outlined: "",
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("fal fa-search"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.$currentUser.isStudent &&
                                  _vm.hasText(_vm.viewNotesText)
                                    ? _c(
                                        "pb-btn",
                                        {
                                          staticClass:
                                            "ml-2 my-1 d-inline-block",
                                          attrs: {
                                            color: "primary",
                                            label: _vm.$t(
                                              "viewInformationLabel"
                                            ),
                                            text: "",
                                            outlined: "",
                                            disabled: _vm.localAppBarDisabled,
                                          },
                                          on: { click: _vm.showViewNotesText },
                                        },
                                        [_c("v-icon", [_vm._v("fal fa-info")])],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "pb-btn",
                                    {
                                      staticClass: "ml-2 my-1 d-inline-block",
                                      attrs: {
                                        id: "print",
                                        color: "primary",
                                        label: _vm.$t("sendToLabel"),
                                        text: "",
                                        outlined: "",
                                        disabled: _vm.localAppBarDisabled,
                                      },
                                      on: { click: _vm.plansShowPrintOptions },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("fal fa-arrow-up-from-square"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "pb-btn",
                    {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.hideRightDrawer,
                          expression: "hideRightDrawer",
                        },
                      ],
                      class: _vm.userMode === "T" ? "mr-3" : "",
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        label: _vm.userSettingsLabel,
                        color: _vm.$vuetify.theme.dark
                          ? undefined
                          : "rgba(0, 0, 0, 0.64)",
                        disabled: _vm.localAppBarDisabled,
                        text: "",
                        id: "account",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.isGenericView
                            ? undefined
                            : (_vm.localDrawerRight = !_vm.localDrawerRight)
                        },
                      },
                    },
                    [
                      _vm.hasProfilePhoto
                        ? _c("v-avatar", { attrs: { size: "36" } }, [
                            _c("img", {
                              staticStyle: { "object-fit": "cover" },
                              attrs: {
                                src: _vm.profilePhotoUrl,
                                alt: _vm.displayName,
                              },
                            }),
                          ])
                        : _c("v-icon", [_vm._v("fas fa-user-circle")]),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c("print-options", { ref: "printOptions" }),
          _c("lesson-manage-status", { ref: "manageStatus" }),
          _c("school-status-dialog", { ref: "applyStatus" }),
          _c("pb-confirm", { ref: "confirm" }),
          _vm.userMode === "T" && _vm.localPage === "plans"
            ? _c(
                "pb-btn",
                {
                  staticClass: "notes-todo-floating-button",
                  attrs: {
                    id: "reminders",
                    label: _vm.$t("remindersLabel"),
                    color: "#676767",
                    "x-small": "",
                    dark: "",
                    fab: "",
                  },
                  on: { click: _vm.showorHideReminders },
                },
                [
                  _c("v-icon", { staticClass: "ml-1" }, [
                    _vm._v(_vm._s(_vm.floatingButtonIcon)),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c("base-toolbar", {
        scopedSlots: _vm._u([
          {
            key: "page-title",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "page-title",
                    staticStyle: { "line-height": "normal" },
                  },
                  [
                    _c("div", [_vm._v(_vm._s(_vm.localTitle))]),
                    !_vm.$currentUser.isStudent
                      ? _c("school-year-selector", {
                          attrs: { outlined: false },
                        })
                      : _vm._e(),
                    _vm.userMode == "S" && _vm.studentId > 0
                      ? [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                style: { height: "20px" },
                                                attrs: {
                                                  elevation: "0",
                                                  color: "primary",
                                                  text: true,
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "switch-teacher__text d-flex",
                                                class: {
                                                  mobile:
                                                    _vm.$vuetify.breakpoint
                                                      .smAndDown,
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.studentTeacherItems.find(
                                                        (a) =>
                                                          a.value ===
                                                          _vm.studentTeacherId
                                                      ).text
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "ml-1 my-auto",
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("fal fa-angle-down")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2325126598
                              ),
                            },
                            [
                              _c(
                                "perfect-scrollbar",
                                {
                                  staticStyle: {
                                    "max-height": "calc(100vh - 120px)",
                                  },
                                },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(
                                      _vm.studentTeacherItems,
                                      function (item, index) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: index,
                                            class: {
                                              "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                                +item.value ===
                                                _vm.studentTeacherId,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.studentTeacherId =
                                                  item.value
                                              },
                                            },
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _c("span", [
                                                _vm._v(_vm._s(item.text)),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c("print-options", { ref: "printOptions" }),
                _c("lesson-manage-status", { ref: "manageStatus" }),
                _c("school-status-dialog", { ref: "applyStatus" }),
                _c("pb-confirm", { ref: "confirm" }),
              ]
            },
            proxy: true,
          },
          {
            key: "mobile-toolbar-left",
            fn: function () {
              return [
                !_vm.localPlansToolbarHidden
                  ? _c(
                      "v-menu",
                      {
                        attrs: { bottom: "", "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "pb-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          ref: "calendarButton",
                                          staticClass: "mr-1",
                                          attrs: {
                                            id: "calendars",
                                            color: "primary",
                                            elevation: "0",
                                            disabled: _vm.localAppBarDisabled,
                                            text: "",
                                            small: "",
                                          },
                                          on: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "down",
                                                  40,
                                                  $event.key,
                                                  ["Down", "ArrowDown"]
                                                )
                                              )
                                                return null
                                              return _vm.focusButton(
                                                "dayButton"
                                              )
                                            },
                                          },
                                        },
                                        "pb-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v("fal fa-calendars"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3671619974
                        ),
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex white px-0 py-4",
                            style: {
                              "flex-wrap": "wrap",
                              width: "225px",
                              "justify-content": "center",
                              "background-color": _vm.darkMode
                                ? "#1e1e1e !important"
                                : "",
                            },
                          },
                          [
                            _c(
                              "pb-btn",
                              {
                                ref: "dayButton",
                                staticClass: "ml-2 my-1 d-inline-block",
                                attrs: {
                                  "input-value": _vm.viewType === "D",
                                  color: "primary",
                                  label: _vm.$t("dayLabel"),
                                  text: "",
                                  outlined: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.doChangeViewType("D")
                                  },
                                  keyup: [
                                    function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "right",
                                          39,
                                          $event.key,
                                          ["Right", "ArrowRight"]
                                        )
                                      )
                                        return null
                                      if (
                                        "button" in $event &&
                                        $event.button !== 2
                                      )
                                        return null
                                      return _vm.focusButton("monthButton")
                                    },
                                    function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "escape",
                                          undefined,
                                          $event.key,
                                          undefined
                                        )
                                      )
                                        return null
                                      return _vm.focusButton("calendarButton")
                                    },
                                  ],
                                },
                              },
                              [_c("v-icon", [_vm._v("fal fa-calendar-day")])],
                              1
                            ),
                            _c(
                              "pb-btn",
                              {
                                ref: "monthButton",
                                staticClass: "ml-2 my-1 d-inline-block",
                                attrs: {
                                  "input-value": _vm.viewType === "M",
                                  color: "primary",
                                  label: _vm.$t("monthLabel"),
                                  text: "",
                                  outlined: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.doChangeViewType("M")
                                  },
                                  keyup: [
                                    function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "right",
                                          39,
                                          $event.key,
                                          ["Right", "ArrowRight"]
                                        )
                                      )
                                        return null
                                      if (
                                        "button" in $event &&
                                        $event.button !== 2
                                      )
                                        return null
                                      return _vm.focusButton("listButton")
                                    },
                                    function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "left",
                                          37,
                                          $event.key,
                                          ["Left", "ArrowLeft"]
                                        )
                                      )
                                        return null
                                      if (
                                        "button" in $event &&
                                        $event.button !== 0
                                      )
                                        return null
                                      return _vm.focusButton("dayButton")
                                    },
                                    function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "escape",
                                          undefined,
                                          $event.key,
                                          undefined
                                        )
                                      )
                                        return null
                                      return _vm.focusButton("calendarButton")
                                    },
                                  ],
                                },
                              },
                              [_c("v-icon", [_vm._v("fal fa-calendar-days")])],
                              1
                            ),
                            _vm.showCycle && !_vm.$currentUser.isStudent
                              ? _c(
                                  "pb-btn",
                                  {
                                    staticClass: "ml-2 my-1 d-inline-block",
                                    attrs: {
                                      value: "C",
                                      "input-value": _vm.viewType === "C",
                                      color: "primary",
                                      label: _vm.$t("cycleLabel"),
                                      text: "",
                                      outlined: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.doChangeViewType("C")
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v("fal fa-calendar-range"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "pb-btn",
                              {
                                ref: "listButton",
                                staticClass: "ml-2 mr-2 my-1 d-inline-block",
                                attrs: {
                                  "input-value": _vm.viewType === "L",
                                  color: "primary",
                                  label: _vm.$t("listLabel"),
                                  text: "",
                                  outlined: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.doChangeViewType("L")
                                  },
                                  keyup: [
                                    function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "left",
                                          37,
                                          $event.key,
                                          ["Left", "ArrowLeft"]
                                        )
                                      )
                                        return null
                                      if (
                                        "button" in $event &&
                                        $event.button !== 0
                                      )
                                        return null
                                      return _vm.focusButton("monthButton")
                                    },
                                    function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "escape",
                                          undefined,
                                          $event.key,
                                          undefined
                                        )
                                      )
                                        return null
                                      return _vm.focusButton("calendarButton")
                                    },
                                  ],
                                },
                              },
                              [_c("v-icon", [_vm._v("fal fa-calendar-lines")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "mobile-toolbar-right",
            fn: function () {
              return [
                _vm.$currentUser.isAdmin
                  ? _c(
                      "pb-btn",
                      {
                        staticClass: "mr-4 my-1",
                        attrs: {
                          id: "print",
                          color: "primary",
                          text: "",
                          disabled: _vm.localAppBarDisabled,
                        },
                        on: { click: _vm.messageTeacher },
                      },
                      [_c("v-icon", [_vm._v("fal fa-message")])],
                      1
                    )
                  : _vm._e(),
                _vm.userMode === "T"
                  ? _c(
                      "v-menu",
                      {
                        attrs: { bottom: "", "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on: onMenu }) {
                                return [
                                  _c(
                                    "pb-btn",
                                    _vm._g(
                                      {
                                        ref: "moreActionsButton",
                                        staticClass: "ml-1",
                                        attrs: {
                                          id: "more",
                                          color: "primary",
                                          label: _vm.$t("moreActionsLabel"),
                                          elevation: "0",
                                          disabled: _vm.localAppBarDisabled,
                                          text: "",
                                          small: "",
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "down",
                                                40,
                                                $event.key,
                                                ["Down", "ArrowDown"]
                                              )
                                            )
                                              return null
                                            return _vm.focusButton("copyButton")
                                          },
                                        },
                                      },
                                      { ...onMenu }
                                    ),
                                    [_c("v-icon", [_vm._v("fal fa-grid")])],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3335614988
                        ),
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex white px-0 py-4",
                            style: {
                              "flex-wrap": "wrap",
                              width: "225px",
                              "justify-content": "center",
                              "background-color": _vm.darkMode
                                ? "#1e1e1e !important"
                                : "",
                            },
                          },
                          [
                            _vm.currentYearId > 0 && _vm.schoolId !== 0
                              ? _c(
                                  "v-menu",
                                  {
                                    attrs: { "close-on-content-click": false },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "pb-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      ref: "applyWeeksButton",
                                                      staticClass:
                                                        "ml-2 my-1 d-inline-block",
                                                      attrs: {
                                                        label:
                                                          _vm.$t(
                                                            "applyWeekTags"
                                                          ),
                                                        color: "primary",
                                                        disabled:
                                                          _vm.localAppBarDisabled,
                                                        text: "",
                                                        outlined: "",
                                                      },
                                                      on: {
                                                        keyup: [
                                                          function ($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "right",
                                                                39,
                                                                $event.key,
                                                                [
                                                                  "Right",
                                                                  "ArrowRight",
                                                                ]
                                                              )
                                                            )
                                                              return null
                                                            if (
                                                              "button" in
                                                                $event &&
                                                              $event.button !==
                                                                2
                                                            )
                                                              return null
                                                            return _vm.focusButton(
                                                              "copyButton"
                                                            )
                                                          },
                                                          function ($event) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "escape",
                                                                undefined,
                                                                $event.key,
                                                                undefined
                                                              )
                                                            )
                                                              return null
                                                            return _vm.focusButton(
                                                              "moreActionsButton"
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    "pb-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("fal fa-tags"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4226872760
                                    ),
                                    model: {
                                      value: _vm.showStatusMenu,
                                      callback: function ($$v) {
                                        _vm.showStatusMenu = $$v
                                      },
                                      expression: "showStatusMenu",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass: "ml-2 mr-2",
                                                attrs: {
                                                  align: "center",
                                                  justify: "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold mb-1 mx-1",
                                                    style: {
                                                      color:
                                                        _vm.$vuetify.theme
                                                          .currentTheme.info,
                                                      "font-size": "12px",
                                                      "max-width": "280px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.displayViewStart
                                                        ) +
                                                        " - " +
                                                        _vm._s(
                                                          _vm.displayViewEnd
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._l(
                                          _vm.localAllSchoolStatus,
                                          function (status) {
                                            return _c(
                                              "v-list-item",
                                              {
                                                key:
                                                  "s" + status.schoolStatusId,
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addRemoveSchoolStatus(
                                                      status
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "lesson-statuses",
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass: "m-2",
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          align: "left",
                                                          justify: "left",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "justify-start",
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: { text: "" },
                                                          },
                                                          [
                                                            _c("v-icon", {
                                                              attrs: {
                                                                color:
                                                                  status.color,
                                                              },
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    status.icon
                                                                  ),
                                                              },
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    status.statusText
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            status.adminOnly
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2",
                                                                    attrs: {
                                                                      color:
                                                                        "grey",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fal fa-lock"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.userMode === "T"
                              ? _c(
                                  "pb-btn",
                                  {
                                    ref: "copyButton",
                                    staticClass: "ml-2 my-1 d-inline-block",
                                    attrs: {
                                      id: "copy",
                                      label: _vm.$t("copyLabel"),
                                      color: "primary",
                                      disabled: _vm.localAppBarDisabled,
                                      text: "",
                                      outlined: "",
                                    },
                                    on: {
                                      click: _vm.openCopyPage,
                                      keyup: [
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "left",
                                              37,
                                              $event.key,
                                              ["Left", "ArrowLeft"]
                                            )
                                          )
                                            return null
                                          if (
                                            "button" in $event &&
                                            $event.button !== 0
                                          )
                                            return null
                                          return _vm.focusButton(
                                            "applyWeeksButton"
                                          )
                                        },
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "right",
                                              39,
                                              $event.key,
                                              ["Right", "ArrowRight"]
                                            )
                                          )
                                            return null
                                          if (
                                            "button" in $event &&
                                            $event.button !== 2
                                          )
                                            return null
                                          return _vm.focusButton("searchButton")
                                        },
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "escape",
                                              undefined,
                                              $event.key,
                                              undefined
                                            )
                                          )
                                            return null
                                          return _vm.focusButton(
                                            "moreActionsButton"
                                          )
                                        },
                                      ],
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("fal fa-copy")])],
                                  1
                                )
                              : _vm._e(),
                            _vm.userMode === "T"
                              ? _c(
                                  "pb-btn",
                                  {
                                    ref: "searchButton",
                                    staticClass:
                                      "ml-2 mr-2 my-1 d-inline-block",
                                    attrs: {
                                      id: "search",
                                      to: "/search",
                                      color: "primary",
                                      label: _vm.$t("searchLessonsLabel"),
                                      disabled: _vm.localAppBarDisabled,
                                      text: "",
                                      outlined: "",
                                    },
                                    on: {
                                      keyup: [
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "left",
                                              37,
                                              $event.key,
                                              ["Left", "ArrowLeft"]
                                            )
                                          )
                                            return null
                                          if (
                                            "button" in $event &&
                                            $event.button !== 0
                                          )
                                            return null
                                          return _vm.focusButton("copyButton")
                                        },
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "escape",
                                              undefined,
                                              $event.key,
                                              undefined
                                            )
                                          )
                                            return null
                                          return _vm.focusButton(
                                            "moreActionsButton"
                                          )
                                        },
                                      ],
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("fal fa-search")])],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }